import { helperMixin } from "@/mixins";
import { defineComponent } from "vue";
export default defineComponent({
    mixins: [helperMixin],
    data() {
        return {
            contentHeader: false,
        };
    },
    async mounted() {
        await this.$nextTick();
        this.contentHeader = document.getElementById("contentHeader")
            ? true
            : false;
    },
    props: {},
});
