import { helperMixin } from "@/mixins";
import { defineComponent } from "vue";
export default defineComponent({
    mixins: [helperMixin],
    data() {
        return {
            contentFooter: false,
        };
    },
    async mounted() {
        await this.$nextTick();
        this.contentFooter = document.getElementById("contentFooter")
            ? true
            : false;
    },
    props: {},
});
