import { createInstance } from "@/lib/fn";
import BaseResourceHttp from "@/lib/resourceHttp";
class SubscriptionPaymentHttp extends BaseResourceHttp {
    constructor() {
        super(...arguments);
        this.ignoreMethods = [];
    }
    baseURL() {
        return "subscription_payment";
    }
}
const subscriptionPaymentHttp = createInstance(SubscriptionPaymentHttp);
export default subscriptionPaymentHttp;
