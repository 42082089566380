import { createInstance } from "@/lib/fn";
import BaseResourceHttp from "@/lib/resourceHttp";
class PaymentMethodHttp extends BaseResourceHttp {
    constructor() {
        super(...arguments);
        this.ignoreMethods = ["create", "delete"];
    }
    baseURL() {
        return "payment_method";
    }
}
const paymentMethodHttp = createInstance(PaymentMethodHttp);
export default paymentMethodHttp;
